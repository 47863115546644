import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

export default function Navigation() {
  return (
    <Nav>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
      <li>
        <Link to="https://impact.themobian.com/login/">Login</Link>
      </li>
      <li>
        <Link to="/terms">Terms</Link>
      </li>
      <li>
        <Link to="/privacy">Privacy</Link>
      </li>
    </Nav>
  );
}

const Nav = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 0 30px;
  justify-content: center;

  li {
    margin: 0 0.5rem;
    padding: 0;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;
