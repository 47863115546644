import React from 'react';
import styled from '@emotion/styled';

import Page from 'Components/Page';
import PersonaForm from 'Components/Persona/PersonaForm';

const PersonaPage = () => {
  return (
    <Page greyBackground title="AI Personas">
      <CentredLayout>
        <Title>AI Personas</Title>
        <Subtitle>Describe your target persona</Subtitle>
        <PersonaForm showFilters />
      </CentredLayout>
    </Page>
  );
};

export default PersonaPage;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin: 0 0 0 0;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin: 0 0 1.25em 0;
`;

const CentredLayout = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0 0 0;

  justify-content: start;
  @media (max-width: 800px) {
    padding: 1em 0 0 0;
  }
`;
