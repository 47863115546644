import { Video } from 'api';

export const generateThumbnailNavigationObject = (content: Video) => {
  const map = {
    youtube: {
      url: 'https://www.youtube.com/watch?v=' + content.video_id,
      name: content.channel_name,
      title: content.video_title,
    },
    tiktok: {
      url: content.video_url,
      name: content.channel_name,
      title: content.video_title,
    },
    xsocial: {
      url: content.post_url,
      name: content.channel_name,
      title: content.channel_nickname,
    },
    article: {
      url: content.video_id,
      name: content.channel_id,
      title: content.article_title,
    },
    iris_tv: {
      url: content.video_id,
      title: content.video_title,
    },
  };

  return {
    pathname: `/content/${encodeURIComponent(map[content.source].url)}`,
    state: {
      content,
      creatorName: map[content.source].name,
      contentTitle: map[content.source].title,
      from: window.location.pathname + window.location.search,
    },
  };
};
