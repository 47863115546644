import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import styled from '@emotion/styled';

import { Video } from 'api';
import CardFlip from './CardFlip';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

interface GalleryProps {
  hasMore: boolean;
  isInitialLoading: boolean;
  isLoading: boolean;
  limit?: number;
  videos: Video[];
  getNavigationState?: (video: Video) => any;
  getNavigationUrl: (video: Video) => string;
  onCardClick?: (video: Video) => void;
  onNext: () => void;
}

const Gallery: React.FC<GalleryProps> = ({
  hasMore,
  isInitialLoading,
  isLoading,
  limit = 60,
  videos,
  getNavigationState,
  getNavigationUrl,
  onCardClick,
  onNext,
}) => {
  return (
    <GalleryContainer id="gallery-container">
      {isLoading && !videos.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />
      )}
      <InfiniteScroll
        style={{ overflowX: 'hidden', alignItems: 'center', justifyContent: 'center' }}
        dataLength={videos.length}
        next={onNext}
        hasMore={hasMore && videos.length < limit}
        loader={<></>}
        scrollThreshold={0.2}
      >
        {!isInitialLoading && (
          <ResponsiveMasonryStyled columnsCountBreakPoints={BREAKPOINTS}>
            <Masonry gutter="5px">
              {videos.map((video) => (
                <Link
                  key={video.video_id}
                  to={getNavigationUrl(video)}
                  onClick={() => onCardClick?.(video)}
                  state={getNavigationState?.(video)}
                >
                  <Card video={video} />
                </Link>
              ))}
            </Masonry>
          </ResponsiveMasonryStyled>
        )}
      </InfiniteScroll>
    </GalleryContainer>
  );
};

export default Gallery;

const Card = ({ video }: { video: Video }) => {
  const title = video.source === 'iris_tv'
    ? 'CTV'
    : ['omny', 'youtube', 'tiktok'].includes(video.source)
    ? video.channel_name
    : video.channel_id;

  const content = video.source === 'omny'
    ? video.podcast_title
    : ['iris_tv', 'youtube', 'tiktok'].includes(video.source)
    ? video.video_title
    : video.article_title;

  return (
    <CardFlip
      backContent={content}
      imageSrc={video.thumbnail_url}
      mainContent={content}
      title={title}
    />
  )
};

const GalleryContainer = styled.div`
  width: 100%;
  padding-top: 1em;
`;

const ResponsiveMasonryStyled = styled(ResponsiveMasonry)`
  margin: 0 auto;
  @media (min-width: 1500px) {
    margin-right: 10%;
    margin-left: 10%;
  }
`;

const BREAKPOINTS = {
  25: 1,
  200: 2,
  300: 3,
  328: 4,
  490: 4,
  675: 5,
  850: 6,
  1000: 6,
  1400: 6,
  1850: 6,
  2000: 6,
  2675: 9,
  3000: 12,
  3250: 13,
};
