import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';
import Page from 'Components/Page';
import VideoInfo from 'Components/Scorecard/VideoInfo';
import ContentSearchBar from 'Components/ContentSearchBar';
import useContentSearch from 'Helpers/useContentSearch';
import Env from 'Helpers/environment';

const ContentDetailPage = () => {
  const { onSearch, search, setSearch } = useContentSearch();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const showContentSearchBar = Env.SearchEnvs.includes(Env.CurrentEnv);

  useEffect(() => {
    if (!query) return;
    setSearch(decodeURIComponent(query));
  }, [query, setSearch]);

  return (
    <Page greyBackground customBackground customBackgroundColor="#ECEFF1" showSearch={!showContentSearchBar}>
      {showContentSearchBar && (
        <ContentSearchBarContainer>
          <ContentSearchBar
            onClear={() => setSearch('')}
            onSearch={onSearch}
            onSuggestionClick={onSearch}
            setValue={setSearch}
            value={search}
          />
        </ContentSearchBarContainer>
      )}
      <VideoInfo />
    </Page>
  );
};

export default ContentDetailPage;

const ContentSearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
`;
