import { useNavigate, useLocation, Link } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';

import Env from 'Helpers/environment';
import { FiltersContext, UrlContext } from 'context';
import MobianLogo from 'logo.svg';
import SearchBar from './SearchBar';

interface PageProps {
  children: React.ReactNode;
  greyBackground?: boolean;
  customBackground?: boolean;
  customBackgroundColor?: string;
  showSearch?: boolean;
  title?: string;
}

const Page: React.FC<PageProps> = ({
  children,
  greyBackground = false,
  customBackground = false,
  customBackgroundColor = '',
  showSearch = false,
  title = 'Mobian',
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUrl } = useContext(UrlContext);
  const {
    setRedirection,
    clearFilters,
  } = useContext(FiltersContext);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (location.state && location.state.from) {
      setRedirection(location.state.from);
    }
  }, [location.state, setRedirection]);

  const handleClearAllFilters = () => {
    clearFilters();
  };

  const handleLogoClick = () => {
    handleClearAllFilters();
    setUrl('');
    navigate('/');
  };

  return (
    <PageContainer
      greyBackground={greyBackground}
      customBackground={customBackground}
      customBackgroundColor={customBackgroundColor}
    >
      <HeaderContainer
        greyBackground={greyBackground}
        customBackground={customBackground}
        customBackgroundColor={customBackgroundColor}
      >
        <Header>
          <Logo src={MobianLogo} alt="Mobian logo" onClick={handleLogoClick} />
        </Header>
        {showSearch && <SearchBar handleClearAllFilters={handleClearAllFilters} />}
      </HeaderContainer>
      <AppContainer>{children}</AppContainer>
      <FooterNavMenu />
    </PageContainer>
  );
};

export default Page;

const PageContainer = styled.div<{
  greyBackground: boolean;
  customBackground: boolean;
  customBackgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background-color: ${(props) =>
    props.customBackground
      ? props.customBackgroundColor
      : props.greyBackground
        ? '#f5f5f5'
        : 'white'};
  position: relative;
`;

const HeaderContainer = styled.header<{
  greyBackground: boolean;
  customBackground: boolean;
  customBackgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 0 var(--page-padding);
  ${(props) => props.greyBackground && `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);`}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1em 0.25em;
  height: var(--header-height);
  box-sizing: border-box;
`;

const Logo = styled.img`
  height: 18px;
  cursor: pointer;
`;

const AppContainer = styled.div`
  padding: 0 var(--page-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-width: 70%;
  flex: 1;
`;

const FooterNavMenu: React.FC = () => {
  return (
    <FooterNavList>
      <li>
        <NavLink to="/">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="https://impact.themobian.com/login/">
          Impact Analytics
        </NavLink>
      </li>
      {Env.PersonaNavEnvs.includes(Env.CurrentEnv) && (
        <li>
          <NavLink to="/persona">
            Personas
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to="/contact">
          Contact
        </NavLink>
      </li>
      <li>
        <NavLink to="/privacy">
          Privacy
        </NavLink>
      </li>
      <li>
        <NavLink to="/terms">
          Terms of Service
        </NavLink>
      </li>
    </FooterNavList>
  );
};

const FooterNavList = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 1em;
  justify-content: center;
  list-style: none;
  margin: 2em 0;
  padding: 0;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
