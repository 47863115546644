// Hits the mobian brand safety API (API Gateway lambdas running in VBS AWS)
// https://github.com/montauklabs/video-brand-safety
import { FilterKey } from 'context';
import { VideoResponse, ApiResponse, VideoLabelResponse } from './types';

import { SENTIMENT, RISK, EMOTION, GENRE, THEME, TONE } from 'context';

const baseUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export const mbsApi = {
  addVideo: async (url: string) => {
    const response = await fetch(`${baseUrl}/video/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey,
      },
      body: JSON.stringify({ 'url': url, 'live': true }),
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },
  getVideoInfo: async (url = null, channelId = null, videoId = null) => {
    let reqUrl = null;
    if (channelId && videoId) {
      reqUrl = `${baseUrl}/video/info?channel_id=${encodeURIComponent(channelId)}&video_id=${encodeURIComponent(videoId)}`;
    } else {
      reqUrl = `${baseUrl}/video/info?url=${encodeURIComponent(url)}`;
    }
    const response = await fetch(reqUrl, {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      },
    });

    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  getMetadata: async (url) => {
    try {
      const response = await fetch(`${baseUrl}/video/metadata?url=${encodeURIComponent(url)}`, {
        method: 'GET',
        headers: {
          'X-Api-Key': apiKey,
        },
      });
      const json = await response.json();
      return {
        status: response.status,
        body: json,
      };
    } catch (error) {
      return {
        status: 'failed',
        body: { error: error.message },
      };
    }
  },
  getLabels: async (labelType, limit = 50): Promise<VideoLabelResponse> => {
    const response = await fetch(`${baseUrl}/settings/${labelType}?items_per_page=${limit}`, {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      },
    });
    const json = await response.json();
    return json;
  },

  getVideoTopic: async (videoId) => {
    const response = await fetch(`${baseUrl}/topic/get?video_id=${videoId}&items_per_page=50`, {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  getVideoEmotion: async (videoId) => {
    const response = await fetch(
      `${baseUrl}/topic/get-emotion?video_id=${videoId}&items_per_page=50`,
      {
        method: 'GET',
        headers: {
          'X-Api-Key': apiKey,
        },
      },
    );
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },
  getRecentVideos: async (
    paginationKey = null,
    videosPerPage = 25,
  ): Promise<ApiResponse<VideoResponse>> => {
    let reqUrl = `${baseUrl}/video/gallery?items_per_page=${videosPerPage}`;

    if (paginationKey) {
      reqUrl += `&pagination_key=${encodeURIComponent(paginationKey)}`;
    }

    const response = await fetch(reqUrl, {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  getBatchVideos: async (
    batchId,
    paginationKey = null,
    videosPerPage = 25,
    filters: Partial<Record<FilterKey, string[]>>
  ): Promise<ApiResponse<VideoResponse>> => {
    let reqUrl = `${baseUrl}/batch/content?batch_id=${batchId}&items_per_page=${videosPerPage}`;

    if (paginationKey) {
      reqUrl += `&pagination_key=${encodeURIComponent(paginationKey)}`;
    }

    const queryParams = [
      { name: 'emotion_options', filters: filters[EMOTION] },
      { name: 'genre_options', filters: filters[GENRE] },
      { name: 'risk_options', filters: filters[RISK] },
      { name: 'sentiment_options', filters: filters[SENTIMENT] },
      { name: 'theme_options', filters: filters[THEME] },
      { name: 'tone_options', filters: filters[TONE] },
    ];

    queryParams.forEach((param) => {
      const queryString = getQueryParameter(param.name, param.filters);
      if (queryString) {
        reqUrl += queryString;
      }
    });

    const response = await fetch(reqUrl, {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  getBatchStatistics: async (batchId) => {
    const response = await fetch(`${baseUrl}/batch/statistics?batch_id=${batchId}`, {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  getVideoExamples: async () => {
    const response = await fetch(`${baseUrl}/video/examples`, {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },
};

const getQueryParameter = (paramName: string, paramValues: string[]) => {
  if (!paramValues?.length) return null;
  const finalParam = paramValues.join(',');
  return `&${paramName}=${encodeURIComponent(finalParam)}`;
};
