import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';

import { Persona } from 'api';
import { FiltersContext } from 'context';

interface MetadataTagDisplayProps {
  emotionData: any;
  topics: any;
  keywordData: any;
  personas: Persona[] | null;
}

const MetadataTagDisplay = ({
  emotionData,
  topics,
  keywordData,
  personas,
}: MetadataTagDisplayProps) => {
  const [emotions, setEmotions] = useState([]);
  const [tones, setTones] = useState([]);
  const [themes, setThemes] = useState([]);
  const [genres, setGenres] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [displayFlags, setDisplayFlags] = useState([]);
  
  const formatTopics = (topics) => {
    const topicTypes = new Set(['tone', 'theme', 'genre'])
    return topics.reduce((accumulator, topic) => {
      if (topic && topicTypes.has(topic.type) && topic.topic) { accumulator[topic.type].push(toTitleCase(topic.topic)) }
      return accumulator;
    }, {
      'tone': [],
      'theme': [],
      'genre': []
    });
  }

  const parseMetadata = useCallback(() => {
    if (emotionData) {
      setEmotions(emotionData.reduce((accumulator, emotion) => {
        if (emotion) { accumulator.push(toTitleCase(emotion.topic)) }
        return accumulator;
      }, []));
    }
    if (topics) {
      const formattedTopics = formatTopics(topics);
      setTones(formattedTopics['tone']);
      setThemes(formattedTopics['theme']);
      setGenres(formattedTopics['genre']);
    }

    if (keywordData) {
      const {
        source,
        article_keywords,
        video_keywords,
        is_opinion = null,
        is_genuine = null,
        is_satire = null,
        is_novel = null,
        has_emotional_resonance = null,
        has_narrative_flow = null,
      } = keywordData;

      const flags = {
        opinion: is_opinion,
        disingenuous: !is_genuine,
        satire: is_satire,
        novel: 0, //is_novel,
        emotional_resonance: 0, //has_emotional_resonance,
        narrative: 0, //has_narrative_flow
      };

      setDisplayFlags(
        Object.keys(flags)
          .filter((key) => flags[key] === 1)
          .map((key) => FLAG_MAPPING[key]),
      );

      switch (source) {
        case 'youtube':
        case 'tiktok':
        case 'iris_tv':
          if (video_keywords) {
            setKeywords(video_keywords.reduce((accumulator, keyword) => {
              if (keyword) { accumulator.push(toTitleCase(keyword)) }
              return accumulator;
            }, []));
          }
          break;
        default:
          if (article_keywords) {
            setKeywords(article_keywords.reduce((accumulator, keyword) => {
              if (keyword) { accumulator.push(toTitleCase(keyword)) }
              return accumulator;
            }, []));
          }
      }
    }
  }, [emotionData, topics, keywordData]);

  useEffect(() => {
    parseMetadata();
  }, [emotionData, topics, keywordData, parseMetadata]);

  const isLoading = !(displayFlags || emotions || tones || themes || genres || keywords);
  if (isLoading) {
    return (
      <Box sx={{ padding: '8px' }}>
        <LoadingMetadataTagRow title="Emotions" />
        <Divider />
        <LoadingMetadataTagRow title="Tones" />
        <Divider />
        <LoadingMetadataTagRow title="Themes" />
        <Divider />
        <LoadingMetadataTagRow title="Genres" />
        <Divider />
        <LoadingMetadataTagRow title="Keywords" />
      </Box>
    );
  }

  const rows = [];
  if (displayFlags.length > 0) {
    rows.push(<MetadataTagRow title="Flags" tags={displayFlags} isFlag />);
  }
  if (emotions.length > 0) {
    rows.push(<MetadataTagRow title="Emotions" tags={emotions} />);
  }
  if (tones.length > 0) {
    rows.push(<MetadataTagRow title="Tones" tags={tones} />);
  }
  if (themes.length > 0) {
    rows.push(<MetadataTagRow title="Themes" tags={themes} />);
  }
  if (genres.length > 0) {
    rows.push(<MetadataTagRow title="Genres" tags={genres} />);
  }
  if (keywords.length > 0) {
    rows.push(<MetadataTagRow title="Keywords" tags={keywords.slice(0, 3)} />);
  }
  if (rows.length > 0 && personas && personas.length > 0) {
    rows.push(<PersonaMetadataTagRow title="Personas" personas={personas} />);
  }

  return (
    <Box sx={{ padding: '8px' }}>
      {rows.map((row, idx) => (
        <React.Fragment key={idx}>
          {row}
          {idx + 1 < rows.length && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MetadataTagDisplay;

const FLAG_MAPPING = {
  opinion: 'Opinion',
  disingenuous: 'Disingenuous',
  satire: 'Satire',
  novel: 'Novel',
  emotional_resonance: 'Emotional Resonance',
  narrative: 'Narrative Flow',
};

const LoadingMetadataTag = ({ width }) => {
  return (
    <Box
      className="emotionLabel"
      sx={{
        backgroundColor: '#CFCFCF',
        padding: '4px 6px 4px 6px',
        gap: '10px',
        borderRadius: '5px',
        fontSize: '12px',
        fontWeight: '600',
        marginBottom: '4px',
        width: width,
        minWidth: width,
        height: '16px',
      }}
    ></Box>
  );
};

interface MetadataTagRowProps {
  title: string;
  tags: string[];
  isFlag?: boolean;
}

const MetadataTagRow = ({ title, tags, isFlag }: MetadataTagRowProps) => {
  return (
    <MetadataTagRowBox>
      <MetadataTagRowTitle>{title}</MetadataTagRowTitle>
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
        {tags.map((tag) => (
          <MetadataTag key={tag} value={tag} isFlag={isFlag} />
        ))}
      </Box>
    </MetadataTagRowBox>
  );
};

interface PersonaMetadataTagRowProps {
  title: string;
  personas: Persona[];
}

const PersonaMetadataTagRow = ({ title, personas }: PersonaMetadataTagRowProps) => {
  return (
    <MetadataTagRowBox>
      <MetadataTagRowTitle>{title}</MetadataTagRowTitle>
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
        {personas.map((persona) => (
          <Link key={persona.meta.id} to={`/persona?id=${persona.meta.id}`}>
            <MetadataTag value={persona.name} />
          </Link>
        ))}
      </Box>
    </MetadataTagRowBox>
  );
};

interface MetadataTagProps {
  isFlag?: boolean;
  value: string;
  onClick?: (value: string) => void;
}

const MetadataTag = ({
  isFlag,
  value,
  onClick,
}: MetadataTagProps) => (
  <Box
    className="emotionLabel"
    onClick={() => onClick?.(value)}
    sx={{
      backgroundColor: isFlag ? '#2196F3' : '#263238',
      color: 'white',
      padding: '4px 6px 4px 6px',
      gap: '10px',
      borderRadius: '5px',
      fontSize: '12px',
      fontWeight: '600',
      marginBottom: '4px',
      cursor: onClick ? 'pointer' : 'unset',
    }}
  >
    {value}
  </Box>
);

const LoadingMetadataTagRow = ({ title }: { title: string }) => {
  return (
    <>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'start',
          width: '100%',
          paddingTop: '6px',
          paddingBottom: '2px',
        }}
      >
        <MetadataTagRowTitle width="30%">{title}</MetadataTagRowTitle>
        <Box sx={{ width: '70%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
          <LoadingMetadataTag width={'12%'} />
          <LoadingMetadataTag width={'16%'} />
          <LoadingMetadataTag width={'12%'} />
          <LoadingMetadataTag width={'16%'} />
        </Box>
      </Box>
    </>
  );
};

const MetadataTagRowTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #78909c;
  padding-right: 8px;
`;

const MetadataTagRowBox = styled(Box)`
  flex-direction: row;
  display: flex;
  align-items: start;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 2px;
`;

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
