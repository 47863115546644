import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Global } from '@emotion/react';

import PersonaIcon from '../../assets/ai-personas.png';
import Scorecards from '../../assets/scorecards.png';
import TargetingImage from '../../assets/targeting.png';
import Navigation from './Navigation';
import MobianLogo from 'logo-white.svg';

const HomePage = () => {
  return (
    <PageContainer maxWidth="xl">
      <Global
        styles={{
          body: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
        }}
      />

      <Nav>
        <LogoContainer>
          <Logo src={MobianLogo} alt="Mobian logo" />
        </LogoContainer>
        <NavRight>
          <StyledLink to="/contact">Contact</StyledLink>
          <StyledButton variant="contained" color="primary" href="https://impact.themobian.com/login/">Login</StyledButton>
        </NavRight>
      </Nav>
      
      <Hero>
        <Header1>
          Reimagining Contextual
        </Header1>
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Mobian leverages highly tuned AI to enable modern contextual measurement and targeting, including brand safety and suitability, emotion, sentiment, tonality, themes, genres, and much more.
        </Typography>
      </Hero>

      <Section>
        <Grid container spacing={2}>
          <ContentCol item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <ContentContainer>
              <Header2>
                AI Personas
              </Header2>
              <Typography variant="body1">
                Build custom personas for seamless and highly resonant contextual targeting at scale across DSPs, SSPs, premium publishers and platforms. Enter a brief description and see example content tailored to your target persona. Contact Mobian to make your personas available for real-time campaign execution.
              </Typography>
            </ContentContainer>
          </ContentCol>
          <ImageCol item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            <ImageContainer>
              <Img src={PersonaIcon} alt="" />
            </ImageContainer>
          </ImageCol>
        </Grid>
      </Section>

      <Section>
        <Grid container spacing={2}>
          <ContentCol item xs={12} md={6}>
            <ContentContainer>
              <Header2>
                Measurement
              </Header2>
              <Typography variant="body1">
                Leverage Mobian measurement to analyze the content ads run alongside, as well as on campaign creative, providing a holistic view of contextual signals and insights into trends tied to outcomes.
              </Typography>
            </ContentContainer>
          </ContentCol>
          <ImageCol item xs={12} md={6}>
            <ImageContainer>
              <Img src={Scorecards} alt="" />
            </ImageContainer>
          </ImageCol>
        </Grid>
      </Section>

      <Section>
        <Grid container spacing={2}>
          <ContentCol item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <ContentContainer>
              <Header2>
                Targeting
              </Header2>
              <Typography variant="body1">
                Mobian signals are available via pre-bid integrations for campaign targeting, helping brands achieve highly relevant and performant reach at scale.
              </Typography>
            </ContentContainer>
          </ContentCol>
          <ImageCol item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            <ImageContainer>
              <Img src={TargetingImage} alt="" />
            </ImageContainer>
          </ImageCol>
        </Grid>
      </Section>

      <CTA>
        <Header2>
          Flexible Integrations
        </Header2>
        <Typography variant="body1">
          Mobian offers a variety of flexible integration options. <StyledLink to="/contact">Contact us</StyledLink> to get started.
        </Typography>
      </CTA>

      <Navigation />
    </PageContainer>
  );
};

export default HomePage;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavRight = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  background-color: #ffffff;
  color: #000000;
  text-transform: none;

  &:hover {
    background-color: #ffffff;
    color: #000000;
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: 80px;
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  padding: 40px 0;
  text-align: center;

  p {
    font-size: 1.25rem;
    max-width: 800px;
  }
`;

const PageContainer = styled(Container)`
  padding: 2rem var(--page-padding);
`;

const Section = styled.div`
  background: linear-gradient(314.44deg, #000000 32.87%,rgb(55, 55, 55) 91.61%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  min-height: 430px;

  p {
    font-size: 1.125rem;

    @media (max-width: 900px) {
      font-size: 1rem;
    }
  }
`;

const Header1 = styled.h1`
  font-size: 3.8rem;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 2.8rem;
  }
`;

const Header2 = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0 1rem 0;

  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;

const ContentCol = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageCol = styled(Grid)`
  align-items: center;
  display: flex;
`;

const ImageContainer = styled.div`
  padding: 30px;

  @media (max-width: 900px) {
    padding: 0 20px;
  }
`;

const ContentContainer = styled.div`
  padding: 50px;
  
  @media (max-width: 900px) {
    padding: 30px;
  }
`;

const Img = styled.img`
  max-width: 100%;
`;

const StyledLink = styled(Link)`
  color: #ffffff;
  text-decoration: underline;
`;

const CTA = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 100px 0;
  text-align: center;

  p {
    max-width: 800px;
  }

  @media (max-width: 900px) {
    padding: 100px 0;
  }
`;