import React from 'react';

import Page from '../Components/Page';
import DiscoverGallery from '../Components/Gallery/DiscoverGallery';

const GalleryPage = () => {
  return (
    <Page>
      <DiscoverGallery />
    </Page>
  );
};

export default GalleryPage;
