import React from 'react';

import { Video } from 'api';
import { generateThumbnailNavigationObject } from 'Helpers/navigation';
import GalleryContainer from './GalleryContainer';
import useContentSearch from 'Helpers/useContentSearch';
import ContentSearchBar from 'Components/ContentSearchBar';

const DiscoverGallery = () => {
  const { onSearch, search, setSearch } = useContentSearch();

  const getNavUrl = (video: Video) => {
    const { pathname } = generateThumbnailNavigationObject(video);
    const query = new URLSearchParams({ query: encodeURIComponent(search) });
    return pathname + (query ? `?${query}` : '');
  };
  const getNavState = (video: Video) => generateThumbnailNavigationObject(video).state;

  return (
    <>
      <ContentSearchBar
        onClear={() => setSearch('')}
        onSearch={onSearch}
        onSuggestionClick={onSearch}
        setValue={setSearch}
        value={search}
      />
      <GalleryContainer getNavigationUrl={getNavUrl} getNavigationState={getNavState} />
    </>
  );
};

export default DiscoverGallery;
