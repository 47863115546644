const DEV = 'dev';
const TEST = 'test';
const STAGING = 'staging';
const PROD = 'production';
const IRIS_TEST = 'iris-test';
const IRIS_PROD = 'iris-production';
const ALL_BUT_PROD = [DEV, TEST, STAGING];

const Env = {
  Dev: DEV,
  Test: TEST,
  Staging: STAGING,
  Prod: PROD,
  IrisTest: IRIS_TEST,
  IrisProd: IRIS_PROD,
  CurrentEnv: process.env.REACT_APP_ENV,
  PersonaEnvs: ALL_BUT_PROD,
  PersonaNavEnvs: [DEV, TEST],
  SearchEnvs: ALL_BUT_PROD,
  DiscoverEnvs: ALL_BUT_PROD,
};

export const notProd = () => {
  return Env.CurrentEnv !== PROD;
}

export default Env;
