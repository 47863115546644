import React from 'react';

import { Video } from 'api';
import { generateThumbnailNavigationObject } from 'Helpers/navigation';
import GalleryContainer from './GalleryContainer';

const HomeGallery = () => {
  const getNavUrl = (video: Video) => generateThumbnailNavigationObject(video).pathname;
  const getNavState = (video: Video) => generateThumbnailNavigationObject(video).state;

  return (
    <GalleryContainer getNavigationState={getNavState} getNavigationUrl={getNavUrl} />
  );
};

export default HomeGallery;
