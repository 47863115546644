import React, { createContext, useState, ReactNode, useMemo, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { garmRiskOptions, sentimentOptions } from '../Helpers/FilterOptions';
import { labelToValueMap } from 'Helpers/utils';

export interface Filter {
  label: string;
  value: string;
}

export type FilterKey =
  | typeof EMOTION
  | typeof GENRE
  | typeof RISK
  | typeof SENTIMENT
  | typeof THEME
  | typeof TONE;

interface FiltersContextType {
  filters: Record<FilterKey, Filter[]>;
  filterDropdowns: FilterOption[];
  lastPage: string;
  clearFilters: () => void;
  initialize: (statistics: Record<FilterKey, [string, number][]>) => void;
  onFilterInputChange: (filterKey: FilterKey) => (keyword: string) => void;
  setFilterByLabel: (filterKey: FilterKey, label: string) => void;
  setAllDropdownOptions: React.Dispatch<React.SetStateAction<Record<FilterKey, Filter[]>>>;
  setDynamicDropdownOptions: React.Dispatch<React.SetStateAction<Record<FilterKey, Filter[]>>>;
  setFilters: React.Dispatch<React.SetStateAction<Record<FilterKey, Filter[]>>>;
  setLastPage: React.Dispatch<React.SetStateAction<string>>;
  setRedirection: (fromPage: string | undefined) => void;
}

export const THEME = 'theme';
export const TONE = 'tone';
export const GENRE = 'genre';
export const EMOTION = 'emotion';
export const RISK = 'risk';
export const SENTIMENT = 'sentiment';

export const AUTOCOMPLETE_FILTER_KEYS = [THEME, TONE, GENRE, EMOTION];

export const FILTER_KEYS = [THEME, TONE, GENRE, EMOTION, RISK, SENTIMENT];

const initialValues = {
  [EMOTION]: [],
  [GENRE]: [],
  [RISK]: [],
  [SENTIMENT]: [],
  [THEME]: [],
  [TONE]: [],
}

const initialOptions: Record<FilterKey, Filter[]> = {
  ...initialValues,
  [RISK]: garmRiskOptions,
  [SENTIMENT]: sentimentOptions,
}

const initialFilters: Record<FilterKey, Filter[]> = {
  ...initialValues,
}

const TOP_COUNT = 100;

const DEFAULT_FILTERS = {
  filters: initialFilters,
  filterDropdowns: [],
  lastPage: '/',
  clearFilters: () => { },
  initialize: () => { },
  onFilterInputChange: () => (keyword: string) => { },
  setAllDropdownOptions: () => { },
  setDynamicDropdownOptions: () => { },
  setFilterByLabel: () => { },
  setFilters: () => { },
  setLastPage: () => { },
  setRedirection: () => { },
};

export const FiltersContext = createContext<FiltersContextType>(DEFAULT_FILTERS);

export type FilterOption = {
  id: FilterKey;
  displaySetter?: ((value: Filter[]) => void);
  options: Filter[];
  setter: (value: Filter[]) => void;
  title: string;
  type?: 'autocomplete' | 'dropdown';
  values: Filter[];
};
interface FiltersProviderProps {
  children: ReactNode;
}

const asc = (a, b) => a.localeCompare(b);
const byCountDesc = (a, b) => b[1] - a[1];
const filterObjectToArray = ({ labels, data }) => labels.map((label, index) => ([label, data[index]]));

export const FiltersProvider: React.FC<FiltersProviderProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [lastPage, setLastPage] = useState<string>('/');
  const [filters, setFilters] = useState<Record<FilterKey, Filter[]>>(initialFilters);
  const [filtersDisplay, setFiltersDisplay] = useState<Record<FilterKey, Filter[]>>(initialFilters);

  // List of all available dropdown options
  const [allDropdownOptions, setAllDropdownOptions] = useState<Record<FilterKey, Filter[]>>(initialOptions);

  // List of dropdown options that are currently displayed
  const [dynamicDropdownOptions, setDynamicDropdownOptions] = useState<Record<FilterKey, Filter[]>>(initialOptions);

  // List of dropdown options that are initially displayed
  const [initialDropdownOptions, setInitialDropdownOptions] = useState<Record<FilterKey, Filter[]>>(initialOptions);

  const initialize = useCallback((statistics: Record<FilterKey, [string, number][]>) => {
    AUTOCOMPLETE_FILTER_KEYS.forEach((key) => {
      if (!statistics[key]) return;
      const { labels, data } = statistics[key];
      const allOptions = labels.map(labelToValueMap);
      const initialOptions = filterObjectToArray({ labels, data })
        .sort(byCountDesc)
        .slice(0, TOP_COUNT)
        .map((o) => o[0])
        .sort(asc)
        .map(labelToValueMap);
      setAllDropdownOptions((current) => ({ ...current, [key]: allOptions }));
      setDynamicDropdownOptions((current) => ({ ...current, [key]: initialOptions }));
      setInitialDropdownOptions((current) => ({ ...current, [key]: initialOptions }));
    });
  }, []);

  useEffect(() => {
    setInitialFiltersPerQueryParams(formatSearchParams(searchParams));
  }, [allDropdownOptions]);

  const onFilterChange = useCallback((filterKey: FilterKey, filters: Filter[]) => {
    const newValue = filters.map((f) => f.value).join(',');
    setSearchParams((current) => {
      const params = new URLSearchParams(current);
      if (newValue.length) {
        params.set(filterKey, newValue);
      } else {
        params.delete(filterKey);
      }
      return params;
    });
  }, [setSearchParams]);

  const formatSearchParams = useCallback((searchParams: URLSearchParams): [string, string[]][] => {
    const params: [string, string[]][] = Array.from(searchParams)
      .filter(([key]) => FILTER_KEYS.includes(key as FilterKey))
      .map(([key, values]) => ([key, decodeURIComponent(values).split(',')]));
    return params;
  }, []);

  const setInitialFiltersPerQueryParams = useCallback((params: [string, string[]][]) => {
    params.forEach(([key, values]) => {
      const filterKey = key as FilterKey;
      if (!allDropdownOptions[filterKey].length) return;
      const queryParamValueToFilter = (value: string) => allDropdownOptions[filterKey].find((o) => o.value === value);
      const nonEmptyFilter = (value: string) => value.length > 0;
      const newFilters = values.filter(nonEmptyFilter).map(queryParamValueToFilter);
      const filterSetter = (current) => {
        const existingFiltersFilter = (o) => !current[filterKey].find((o2) => o2.value === o.value);
        const filteredNewFilters = newFilters.filter(existingFiltersFilter);
        return { ...current, [filterKey]: [...current[filterKey], ...filteredNewFilters] };
      };
      setFilters(filterSetter);
      if ([SENTIMENT, RISK].includes(filterKey)) {
        setFiltersDisplay(filterSetter);
      }
    });
  }, [allDropdownOptions]);

  const makeSetter = useCallback((filterKey: FilterKey) => (filters: Filter[]) => {
    setFilters((current) => ({ ...current, [filterKey]: filters }));
    onFilterChange(filterKey, filters);
  }, [onFilterChange]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setFiltersDisplay(initialFilters);
    setSearchParams({});
  }, [setSearchParams]);

  const setRedirection = useCallback((fromPage: string | undefined): void => setLastPage(fromPage || '/'), [setLastPage]);

  const getFilteredOptions = useCallback((filterKey: FilterKey) => (keyword: string) =>
    allDropdownOptions[filterKey].filter((option) => option.label.toLowerCase().includes(keyword.toLowerCase())), [allDropdownOptions]);

  const onFilterInputChange = useCallback((filterKey: FilterKey) => (keyword: string) => {
    if (!keyword) {
      return setDynamicDropdownOptions((current) => ({ ...current, [filterKey]: initialDropdownOptions[filterKey] }));
    };
    if (keyword.length < 3) {
      return setDynamicDropdownOptions((current) => ({ ...current, [filterKey]: [] }));
    };
    const filteredOptions = getFilteredOptions(filterKey)(keyword);
    setDynamicDropdownOptions((current) => ({ ...current, [filterKey]: filteredOptions }));
  }, [getFilteredOptions, initialDropdownOptions, setDynamicDropdownOptions]);

  const filterDropdowns: FilterOption[] = useMemo(() => [
    {
      id: RISK,
      title: 'Risk',
      options: garmRiskOptions,
      values: filtersDisplay[RISK],
      displaySetter: (value: Filter[]) => setFiltersDisplay((current) => ({ ...current, [RISK]: value })),
      setter: makeSetter(RISK),
    },
    {
      id: SENTIMENT,
      title: 'Sentiment',
      options: sentimentOptions,
      values: filtersDisplay[SENTIMENT],
      displaySetter: (value: Filter[]) => setFiltersDisplay((current) => ({ ...current, [SENTIMENT]: value })),
      setter: makeSetter(SENTIMENT),
    },
    {
      id: EMOTION,
      title: 'Emotion',
      options: dynamicDropdownOptions[EMOTION],
      values: filters[EMOTION],
      setter: makeSetter(EMOTION),
      type: 'autocomplete',
    },
    {
      id: GENRE,
      title: 'Genre',
      options: dynamicDropdownOptions[GENRE],
      values: filters[GENRE],
      setter: makeSetter(GENRE),
      type: 'autocomplete',
    },
    {
      id: THEME,
      title: 'Theme',
      options: dynamicDropdownOptions[THEME],
      values: filters[THEME],
      setter: makeSetter(THEME),
      type: 'autocomplete',
    },
    {
      id: TONE,
      title: 'Tone',
      options: dynamicDropdownOptions[TONE],
      values: filters[TONE],
      setter: makeSetter(TONE),
      type: 'autocomplete',
    },
  ], [filters, filtersDisplay, dynamicDropdownOptions, makeSetter]);

  const setFilterByLabel = useCallback((filterKey: FilterKey, label: string) => {
    const dropdownOption = filterDropdowns.find((dropdown) => dropdown.id === filterKey);
    if (!dropdownOption) return;
    const filter = dropdownOption.options.find((option) => option.label === label);
    if (!filter) return;
    dropdownOption.setter([filter]);
    dropdownOption.displaySetter?.([filter]);
  }, [filterDropdowns]);

  return (
    <FiltersContext.Provider value={{
      filters,
      filterDropdowns,
      lastPage,
      clearFilters,
      initialize,
      onFilterInputChange,
      setAllDropdownOptions,
      setDynamicDropdownOptions,
      setFilterByLabel,
      setFilters,
      setLastPage,
      setRedirection,
    }}>
      {children}
    </FiltersContext.Provider>
  );
};
