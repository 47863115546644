import React, { useEffect, useState } from 'react';

import api, { Video } from 'api';
import Gallery from './Gallery';

const VIDEOS_PER_PAGE = 60;

const GalleryContainer = ({
  getNavigationState,
  getNavigationUrl,
  onCardClick
}: {
  getNavigationState?: (content: Video) => any,
  getNavigationUrl: (content: Video) => string,
  onCardClick?: (content: Video) => void
}) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [paginationKey, setPaginationKey] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  
  const fetchRecentVideos = async (paginationKey = null) => {
    try {
      setIsLoading(true);
      const recentVideosResponse = await api.mbs.getRecentVideos(
        paginationKey,
        VIDEOS_PER_PAGE,
      );
      const newVideos = recentVideosResponse.body?.videos || [];
      setVideos((prevVideos) => {
        const videoSet = new Set(prevVideos.map((video) => video.video_id));
        const uniqueNewVideos = newVideos.filter((video) => !videoSet.has(video.video_id));
        return [...prevVideos, ...uniqueNewVideos];
      });
      setPaginationKey(recentVideosResponse.body.pagination_key);
      setHasMore(recentVideosResponse.body.pagination_key !== null);
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onNext = () => {
    fetchRecentVideos(paginationKey);
  };

  useEffect(() => {
    fetchRecentVideos();
  }, []);

  useEffect(() => {
    if (!isInitialLoad) return;
    setIsInitialLoad(isLoading);
  }, [isLoading, isInitialLoad]);

  return (
    <Gallery
      hasMore={hasMore}
      isInitialLoading={isInitialLoad}
      isLoading={isLoading}
      getNavigationState={getNavigationState}
      getNavigationUrl={getNavigationUrl}
      onCardClick={onCardClick}
      onNext={onNext}
      videos={videos}
      limit={120}
    />
  );
};

export default GalleryContainer;
