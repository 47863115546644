import Env, { notProd } from 'Helpers/environment';

export const labelToValue = (label: string) => {
  return label.trim().toLowerCase(); // No encoding here
}

export const labelToValueMap = (label: string) => ({
  label,
  value: labelToValue(label), // Still plain text
});

export const isMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}

export const debug = (...args: any[]) => {
  if (notProd()) {
    console.log(...args);
  }
}
