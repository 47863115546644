import React from 'react';
import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';

const CircleAndStatsCell = ({
  title,
  topStats,
  totalCount,
  sx,
  titlePosition = 'top',
  descriptionPosition = 'bottom',
  description,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const titleOrder = titlePosition === 'top' ? ['title', 'content'] : ['content', 'title'];
  const descriptionOrder =
    descriptionPosition === 'top'
      ? ['description', 'circleAndStats']
      : ['circleAndStats', 'description'];

  const topThemes = topStats.slice(0, 5);

  return (
    <Grid
      item
      sx={{
        border: '1px solid #E7E7E7',
        textAlign: 'center',
        padding: 2,
        backgroundColor: '#000',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        ...sx,
      }}
    >
      {titleOrder.map((item, index) =>
        item === 'title' ? (
          <Typography key={index} variant="h4" sx={{ fontSize: isSmallScreen ? '1.5rem' : '2rem' }}>
            {title}
          </Typography>
        ) : (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.5rem 1rem', // Reduced padding
              flex: 1,
              margin: isSmallScreen ? '0.5rem 0' : '1rem 0', // Adjust margin for different screen sizes
            }}
          >
            {descriptionOrder.map((item, index) =>
              item === 'circleAndStats' ? (
                <Box
                  key={index}
                  sx={{
                    width: '100%',
                    flex: 1,
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      aspectRatio: '1 / 1',
                      width: { xs: '100px', sm: '110px', md: '130px', lg: '120px', xl: '132px' },
                      height: { xs: '100px', sm: '110px', md: '130px', lg: '120px', xl: '132px' },
                      borderRadius: '50%',
                      background: 'linear-gradient(to right, #e0d2f9, #4e3991)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: { xs: '20px', md: '20px', lg: '30px' }, // Increase the margin to add more spacing
                      padding: '10px', // Adjust the padding to create space for the hollow effect
                    }}
                  >
                    <Box
                      sx={{
                        aspectRatio: '1 / 1',
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        backgroundColor: '#000', // Background color to match the parent component's background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="white"
                        sx={{ fontSize: isSmallScreen ? '1.5rem' : '2rem' }}
                      >
                        {totalCount}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginBottom: '10px',
                        fontSize: { xs: '1rem', sm: '1.25rem', md: '1.15rem', lg: '1.25rem' },
                        fontWeight: '525',
                      }}
                    >
                      Top {topThemes.length} Themes
                    </Typography>
                    {topThemes.map((theme, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        sx={{ fontSize: isSmallScreen ? '0.875rem' : '1rem', marginBottom: '5px' }}
                      >
                        {theme.label}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box key={index} sx={{ width: '100%', flex: 1 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: isSmallScreen ? '0.875rem' : '1rem', marginTop: '15px' }}
                  >
                    {description ? (
                      description
                    ) : (
                      <>
                        Mobian detected{' '}
                        <Box component="span" fontWeight="fontWeightBold">
                          {totalCount}
                        </Box>{' '}
                        unique {title.toLowerCase()}.
                      </>
                    )}
                  </Typography>
                </Box>
              ),
            )}
          </Box>
        ),
      )}
    </Grid>
  );
};

export default CircleAndStatsCell;
