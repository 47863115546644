import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Chip, Alert, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link } from 'react-router-dom';

import api, { Persona } from 'api';
import TextArea from 'Components/Persona/TextArea';
import TimedLoadingBar from 'Components/Persona/TimedLoadingBar';
import usePersonaCreate from 'Helpers/persona/usePersonaCreate';
import usePersonaGallery from 'Helpers/persona/usePersonaGallery';
import CardFlip from 'Components/Gallery/CardFlip';
import Input from 'Components/Persona/Input';
import LanguageSelect from 'Components/Persona/LanguageSelect';
import { useObjectQueryState, QueryStringData } from 'Helpers/useQueryState';

interface QueryStringState {
  id: number | null;
  domain: string | null;
  language: string | null;
}

const PERSONA_CREATE_TIME = 30; // seconds
const PERSONA_SEARCH_TIME = 12; // seconds

export default function PersonaForm({ isDark = false, showFilters = false }: { isDark?: boolean, showFilters?: boolean }) {
  const [persona, setPersona] = useState<Persona | null>(null);
  const [message, setMessage] = useState<string>('');

  const [queryState, setQueryState] = useObjectQueryState<QueryStringState>(
    ['id', 'domain', 'language'],
    loadQsState,
    serializeQsState,
  );
  const setDomain = (d: string) => setQueryState({ ...queryState, domain: d });
  const setLanguage = (l: string) => setQueryState({ ...queryState, language: l });
  const setPersonaId = (id: number | null) => setQueryState({ ...queryState, id: id });
  const clearQuerystring = () => setQueryState({ id: null, domain: null, language: null });
  const { domain, language, id: personaId } = queryState;

  const {
    websites,
    resetGallery,
    fetchWebsites,
    state: galleryState,
  } = usePersonaGallery(persona, domain, language);

  const { handleSendMessage, state: personaState } = usePersonaCreate(
    persona,
    setPersona,
    personaId,
    setPersonaId,
    message,
    setMessage,
    resetGallery,
  );

  const onClear = () => {
    clearQuerystring();
    setMessage('');
    setPersona(null);
  };

  const onTopicDelete = (topicId: number) => () => {
    api.persona
      .deleteTopic(topicId)
      .then(() => {
        setPersona({
          ...persona,
          topics: persona.topics.map((t) => (t.id === topicId ? { ...t, is_active: false } : t)),
        });
        resetGallery();
      })
      .catch((error) => {
        console.error('Error deleting topic:', error);
      });
  };

  const fromPath = useMemo(() => {
    const base = persona ? `/persona?id=${persona.meta.id}` : '/persona';
    return domain ? `${base}&domain=${encodeURIComponent(domain)}` : base;
  }, [persona, domain]);

  const isGalleryVisible = !personaState.loading;
  const isLoaded = Boolean(persona);
  const isTopicDeletable =
    persona?.topics.filter((t) => t.is_active).length > 1 && !persona?.meta.is_mobian;
  const isPersonaDisabled =
    personaState.loading || galleryState.isLoading || persona?.meta.is_mobian;
  const isGalleryDisabled = galleryState.isLoading;
 
  return (
    <>
      <SubmissionForm>
        <TextArea
          placeholder="Briefly describe your target persona..."
          message={message}
          setMessage={setMessage}
          disabled={isPersonaDisabled}
          maxWidth={600}
        />
        {showFilters && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                autoComplete="off"
                disabled={isGalleryDisabled}
                value={domain || ''}
                onChange={(e) => setDomain(e.target.value.toLowerCase())}
                placeholder="Domain (site.com)"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LanguageSelect
                value={language || ''}
                onChange={(language) => setLanguage(language)}
                placeholder="Language"
                disabled={isGalleryDisabled}
              />
            </Grid>
          </Grid>
        )}
        <Buttons>
          <ClearButton
            disabled={personaState.loading}
            onClick={onClear}
            sx={{
              color: isDark ? '#fff' : '#263238',
            }}
          >
            Clear
          </ClearButton>
          <SendButton
            variant="contained"
            size="small"
            onClick={handleSendMessage}
            disabled={personaState.loading || !message || message === persona?.prompt}
            sx={{
              color: isDark ? '#263238' : '#fff',
              backgroundColor: isDark ? '#fff' : '#263238',
              '&:disabled': {
                backgroundColor: isDark ? '#a0a0a0' : '#263238',
                color: isDark ? '#263238' : '#fff',
              },
              '&:hover': {
                backgroundColor: isDark ? '#eee' : '#263238',
              },
            }}
          >
            Send
          </SendButton>
        </Buttons>
      </SubmissionForm>
      {personaState.loading && (
        <MaxWidthContainer>
          <TimedLoadingBar label="Generating persona..." time={PERSONA_CREATE_TIME} />
        </MaxWidthContainer>
      )}
      {personaState.error && (
        <ErrorAlert severity="error">Failed to generate a persona.</ErrorAlert>
      )}
      {isLoaded && !personaState.loading && (
        <MaxWidthContainer>
          <PersonaDisplay>
            <div className="header">
              <h3>Persona Summary</h3>
              {persona?.meta.is_mobian && (
                <MobianChip
                  label="Mobian Persona"
                  size="small"
                  isDark={isDark}
                />
              )}
            </div>
            <p>{persona.description}</p>
            <p>Content includes these topics: </p>
            <TopicsContainer>
              {persona.topics
                .filter((t) => t.is_active)
                .map((t) => (
                  <TopicChip
                    key={t.id}
                    label={t.title}
                    size="small"
                    onDelete={isTopicDeletable ? onTopicDelete(t.id) : null}
                    deleteIcon={isTopicDeletable ? <CancelIcon /> : null}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      if (t.query) {
                        navigator.clipboard
                          .writeText(t.query)
                          .catch((e) => console.error('Failed to copy text:', e));
                      }
                    }}
                    isDark={isDark}
                  />
                ))}
            </TopicsContainer>
          </PersonaDisplay>
        </MaxWidthContainer>
      )}
      {persona && isGalleryVisible && galleryState.isLoading && (
        <MaxWidthContainer>
          <TimedLoadingBar label="Searching for content..." time={PERSONA_SEARCH_TIME} />
        </MaxWidthContainer>
      )}
      {persona && isGalleryVisible && (
        <GalleryContainer>
          <InfiniteScroll
            style={{ overflowX: 'hidden', alignItems: 'center', justifyContent: 'center' }}
            dataLength={websites.length}
            next={fetchWebsites}
            hasMore={galleryState.hasMore}
            loader={<></>}
            scrollThreshold={0.2}
          >
            <ResponsiveMasonryStyled columnsCountBreakPoints={BREAKPOINTS}>
              <Masonry gutter="5px">
                {websites.map((website) => (
                  <Link
                    to={`/content/${encodeURIComponent(website.url)}`}
                    state={{
                      content: null,
                      creatorName: website.creator_name,
                      contentTitle: website.title,
                      from: fromPath,
                    }}
                    key={website.video_id}
                  >
                    <CardFlip
                      imageSrc={website.thumbnail_url}
                      mainContent={website.title}
                      title={website.creator_name}
                      backContent={website.title}
                    />
                  </Link>
                ))}
              </Masonry>
            </ResponsiveMasonryStyled>
          </InfiniteScroll>
        </GalleryContainer>
      )}
    </>
  )
}

const PersonaDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5em;
  margin: 1.5em 0 1.5em 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.25em;
  }

  h3 {
    margin: 0;
    font-size: 1em;
  }
  p {
    margin: 0;
    text-align: left;
    opacity: 0.75;
    font-size: 0.75em;
    max-width: 600px;
    @media (max-width: 800px) {
      font-size: 0.7em;
    }
  }
  @media (max-width: 800px) {
    margin: 1em 0 0 0;
    .header {
      margin-bottom: 0;
    }
  }
`;

const SubmissionForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  max-width: 600px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.5em;
  width: 100%;
  justify-content: flex-end;
`;

const ErrorAlert = styled(Alert)`
  width: 100%;
  max-width: calc(600px - 2em);
  margin: 1em 0;
`;

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;

const TopicsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25em;
  margin-top: 0.5em;
`;

const TopicChip = styled(Chip)<{ isDark?: boolean }>(props => ({
  fontSize: '0.7em',
  marginBottom: '0.25em',
  '.MuiChip-deleteIcon': {
    fontSize: '1em',
  },
  backgroundColor: props.isDark ? '#fff' : '#263238',
  color: props.isDark ? '#263238' : '#fff',
}));

const MobianChip = styled(Chip)<{ isDark?: boolean }>(props => ({
  fontSize: '0.7em',
  backgroundColor: props.isDark ? '#fff' : '#263238',
  color: props.isDark ? '#263238' : '#fff',
}));

const GalleryContainer = styled.div`
  width: 100%;
`;

const ResponsiveMasonryStyled = styled(ResponsiveMasonry)`
  margin: 0 auto;
  @media (min-width: 1500px) {
    margin-right: 10%;
    margin-left: 10%;
  }
`;

const ClearButton = styled(Button)`
  background-color: transparent;
  text-transform: capitalize;
  padding: 0;
  font-size: 0.75em;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const SendButton = styled(Button)`
  min-width: 80px;
  text-transform: capitalize;
`;

const BREAKPOINTS = {
  25: 1,
  200: 2,
  300: 3,
  328: 4,
  490: 4,
  675: 5,
  850: 6,
  1000: 6,
  1400: 6,
  1850: 6,
  2000: 6,
  2675: 9,
  3000: 12,
  3250: 13,
};

const loadQsState = (qs: QueryStringData): QueryStringState => {
  const loadedState = {
    domain: qs['domain'],
    language: qs['language'],
    id: null,
  };
  const idQuery = qs['id'];
  if (idQuery !== null) {
    const parsed = parseInt(idQuery, 10);
    loadedState['id'] = isNaN(parsed) ? null : parsed;
  }
  return loadedState;
};

const serializeQsState = (state: QueryStringState): QueryStringData => ({
  domain: state.domain ?? '',
  language: state.language ?? '',
  id: state.id === null ? '' : state.id.toString(),
});
