import React from 'react';
import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';

const colors = ['#3691ec', '#54e2c8', '#71cf3b', '#fbd344', '#ed664b', '#eb4894'];

const InfographicAndStatsCell = ({
  description,
  descriptionPosition = 'bottom',
  title,
  titlePosition = 'top',
  topStats,
  totalCount,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const titleOrder = titlePosition === 'top' ? ['title', 'content'] : ['content', 'title'];
  const descriptionOrder =
    descriptionPosition === 'top' ? ['description', 'infographic'] : ['infographic', 'description'];

  return (
    <Grid
      item
      sx={{
        border: '1px solid #E7E7E7',
        textAlign: 'center',
        padding: 2,
        backgroundColor: '#000',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {titleOrder.map((item, index) =>
        item === 'title' ? (
          <Typography key={index} variant="h4" sx={{ fontSize: isSmallScreen ? '1.5rem' : '2rem' }}>
            {title}
          </Typography>
        ) : (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              padding: '1rem',
              flex: 1,
            }}
          >
            {descriptionOrder.map((item, index) =>
              item === 'infographic' ? (
                <Box
                  key={index}
                  sx={{
                    width: '100%',
                    flex: 1,
                    margin: '1rem 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    {topStats.map((stat, index) => (
                      <Box
                        key={index}
                        sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}
                      >
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            backgroundColor: colors[(index + 3) % colors.length],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: { xs: '7px', sm: '10px' },
                            marginLeft: '10px',
                          }}
                        >
                          <Typography
                            variant="h6"
                            color="white"
                            sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }}
                          >
                            {index + 1}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: { xs: '14px', sm: '0.8rem', md: '1rem' } }}
                        >
                          {stat.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box key={index} sx={{ width: '100%', flex: 1 }}>
                  {description ? (
                    <Typography
                      variant="body1"
                      sx={{ fontSize: isSmallScreen ? '0.875rem' : '1rem', marginBottom: '10px' }}
                    >
                      {description}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      display="block"
                      sx={{ fontSize: isSmallScreen ? '0.875rem' : '1rem', marginBottom: '10px' }}
                    >
                      Mobian detected{' '}
                      <Box component="span" fontWeight="fontWeightBold">
                        {totalCount}
                      </Box>{' '}
                      unique {title.toLowerCase()}s.
                    </Typography>
                  )}
                </Box>
              ),
            )}
          </Box>
        ),
      )}
    </Grid>
  );
};

export default InfographicAndStatsCell;
